.workout-search {
  position: relative;
}

.options-list {
  background-color: white;
  z-index: 999;
  position: absolute;
  border-radius: 4px;
  box-shadow: var(--shadow-elevated);
  width: 100%;
}

.options-list ul {
  list-style: none;
  max-height: 75vh;
  width: 100% !important;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
}

.options-list li {
  padding-left: 36px;
  display: flex;
  gap: 0px;
  flex-direction: column;
}

.options-list li:last-child {
  margin-bottom: 8px;
}

.content {
  /* border-bottom: 1px solid var(--divider); */
  padding: 8px 8px 8px 0;
}

.active .content,
.before-active .content {
  border-color: transparent;
}

.options-list h5 {
  margin-left: 36px;
  /* border-bottom: 1px solid var(--divider); */
  padding: 20px 0 8px;
  margin-bottom: 0px;
}

.options-list li .name {
  font-weight: 500;
}

.options-list .secondary-info {
  font-size: 14px;
  color: var(--secondary-text);
}

/*when "press esc" shown */
.options-list li:last-child {
  border-bottom: none;
}

.options-list.with-key ul {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.options-list.short.with-key li:last-child {
  margin-bottom: 0;
}

.options-list .item {
  font: var(--h5);
  color: var(--secondary-text);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
  padding: 32px 9px 16px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0px,
    rgba(255, 255, 255, 1) 10px
  );
  margin: -20px 0 0 0;
  position: relative;
  height: 64px;
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

/*when there's not too many matches in the list of moves*/
.options-list.short .item {
  margin-top: 0;
  height: 40px;
  align-items: baseline;
}

.options-list li mark {
  background-color: var(--accent-highlight);
}

.options-list .item span {
  color: black;
}

.workout-search input {
  padding-left: 36px;
}

.workout-search .icon {
  stroke: var(--accent);
  position: absolute;
  top: 14px;
  left: 8px;
}
