.day {
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: var(--divider);
  padding: 2px;
  min-height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  /* scroll-snap-align: start; - weird effects*/
}

.day.loading {
  overflow: hidden;
}

.day.loading::after {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  animation: shimmer 1.3s infinite linear;
  content: '';
  z-index: 9;
  pointer-events: none;
}

@keyframes shimmer {
  100% {
    transform: translateY(100%);
  }
}

.icon-add {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: var(--accent);
  display: none;
}

.day:hover .icon-add {
  display: flex;
}

.day:first-child,
.day:nth-child(7n + 8) {
  border-left: none;
}

.day:nth-child(7n) {
  border-right: none;
}

/* Weekends */
.day:nth-child(7n + 6),
.day:nth-child(7n + 7) {
  background-color: var(--bg-light-gray);
}

.s.day:nth-child(7n + 6) {
  background-color: transparent;
}

.s.day:first-child,
.s.day:nth-child(7n + 8),
.s.day:nth-child(7n + 7) {
  background-color: var(--bg-light-gray);
}
/**********/
.day:hover,
.day.drag-hover,
.day.active {
  border: 4px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  margin: -2px -2px -1px;
  z-index: 10;
  padding: 0px;
}

.day:hover:first-child,
.day:hover:nth-child(7n + 8),
.day.active:first-child,
.day.active:nth-child(7n + 8) {
  border-left-width: 6px;
  padding-left: 0;
}

.day:hover:first-child .content,
.day:hover:nth-child(7n + 8) .content,
.day.active:first-child .content,
.day.active:nth-child(7n + 8) .content,
.day:hover:first-child .num-container,
.day:hover:nth-child(7n + 8) .num-container,
.day.active:first-child .num-container,
.day.active:nth-child(7n + 8) .num-container {
  margin-left: -2px;
}

.day:hover:nth-child(1),
.day:hover:nth-child(2),
.day:hover:nth-child(3),
.day:hover:nth-child(4),
.day:hover:nth-child(5),
.day:hover:nth-child(6),
.day:hover:nth-child(7),
.day.active:nth-child(1),
.day.active:nth-child(2),
.day.active:nth-child(3),
.day.active:nth-child(4),
.day.active:nth-child(5),
.day.active:nth-child(6),
.day.active:nth-child(7) {
  border-top-width: 5px;
}

.day:hover:nth-child(1) .daynum,
.day:hover:nth-child(2) .daynum,
.day:hover:nth-child(3) .daynum,
.day:hover:nth-child(4) .daynum,
.day:hover:nth-child(5) .daynum,
.day:hover:nth-child(6) .daynum,
.day:hover:nth-child(7) .daynum,
.day.active:nth-child(1) .daynum,
.day.active:nth-child(2) .daynum,
.day.active:nth-child(3) .daynum,
.day.active:nth-child(4) .daynum,
.day.active:nth-child(5) .daynum,
.day.active:nth-child(6) .daynum,
.day.active:nth-child(7) .daynum {
  margin-top: 3px;
}
.day.active {
  border-color: var(--accent);
  z-index: 20;
}

.day.active:hover {
  border-color: var(--accent-hover);
}

.daynum {
  text-align: right;
  min-width: 16px;
  padding: 0 4px;
  margin: 4px 2px 0 0;
  position: relative;
  z-index: 99;
}

.day.active .daynum,
.day:hover .daynum {
  margin: 4px 3px 0 0;
}

.daynum.today {
  color: white !important;
  background-color: var(--accent-red);
  border-radius: 20px;
  text-align: center;
}

.daynum.non-current {
  color: rgba(0, 0, 0, 0.5);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: calc(100% - 28px);
  /* overflow-y: hidden; */
  transition: height 0.35s;
  flex: 0;
}

.is-loading .content {
  height: 0;
}

.wrap-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  height: 24px;
  bottom: 0;
  position: absolute;
  z-index: 2;
}

.is-loading .wrap-gradient {
  opacity: 0;
}

/* Weekends */
.day:nth-child(7n + 6) .wrap-gradient,
.day:nth-child(7n + 7) .wrap-gradient {
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  );
}

.s.day:nth-child(7n + 6) .wrap-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.s.day:first-child .wrap-gradient,
.s.day:nth-child(7n + 8).wrap-gradient,
.s.day:nth-child(7n + 7) .wrap-gradient {
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  );
}
/**********/

.day:hover .wrap-gradient,
.day.active .wrap-gradient {
  margin-top: -24px;
}

:global([class*='is-expanded']) {
  .wrap-gradient {
    display: none;
  }
}

.cycle.cycle-green {
  --cycle-color: var(--color-cycle-green);
}

.cycle.cycle-blue {
  --cycle-color: var(--color-cycle-blue);
}

.cycle.cycle-red {
  --cycle-color: var(--color-cycle-red);
}

.cycle.cycle-yellow {
  --cycle-color: var(--color-cycle-yellow);
}

.day.cycle {
  background: linear-gradient(
    180deg,
    var(--cycle-color) 0px,
    var(--cycle-color) 3px,
    #fff 4px
  );
  background-repeat: no-repeat;
}

.day.active.cycle,
.day:hover.cycle {
  background: linear-gradient(
    180deg,
    var(--cycle-color) 0px,
    var(--cycle-color) 1px,
    #fff 2px
  );
  background-repeat: no-repeat;
}

.num-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

/* .active .cycle-title-container {
  top: -2px;
} */

.daynum-container {
  /* Don't grow or shrink, preserve width */
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

.daynum {
  white-space: nowrap;
}

.cycle-title-container {
  font-size: 12px;
  white-space: nowrap;
  position: relative;
  top: -4px;
  height: 24px;
  margin: 0 0 -2px -2px;
  color: black;
  display: flex;
  background-color: var(--cycle-color);
  --border-radius: 12px;
  flex: 1 1 auto;
  /* Ensure it can shrink below content size */
  min-width: 0;
}
.cycle-title-wrapper {
  background-color: white;
  display: flex;
  /* Allow wrapper to shrink */
  flex: 0 1 auto;
  min-width: 0;
}

.cycle-title {
  text-overflow: ellipsis;
  background-color: var(--cycle-color);
  /* Allow title to shrink with ellipsis */
  flex: 0 1 auto;
  min-width: 0;
  white-space: nowrap;
  padding: 0 16px 0 3px;
  min-width: 0;
  overflow: hidden;
  border-bottom-right-radius: var(--border-radius);
}

.icon-container {
  padding: 0 0 0 6px;
  /* Don't grow or shrink, maintain size */
  flex: 0 0 auto;
  background-color: var(--cycle-color);
}

.cycle-title-after {
  width: var(--border-radius);
  height: 100%;
  background-color: white;
  color: black;
  /* Don't grow or shrink */
  flex: 1 0 auto;
  border-top-left-radius: var(--border-radius);
}

.cycle-title-container svg {
  top: 1px;
  margin-left: -2px;
  position: relative;
  color: var(--secondary-text);
}

/* .day.cycle-green {
  background: linear-gradient(
    180deg,
    var(--accent) 0px,
    var(--accent) 4px,

    #fff 4px
  );
} */
