.li {
  display: flex;
  justify-content: space-between;
}

.secondary {
  font: var(--small-text);
  color: var(--secondary-text);
}

.li a {
  font-size: 16px;
  float: right;
}

.li:hover {
  background-color: var(--bg-gray);
  margin-left: -4px;
  padding-left: 16px;
}

.avatar {
  float: left;
}

.left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.text-info {
  display: flex;
  flex-direction: column;
}

.name {
  font: var(--h4);
}

.name .text {
  float: left;
}

.button {
  opacity: 0;
}

.li:hover .button {
  opacity: 1;
}

.index {
  font: var(--small-text);

  color: var(--secondary-text);
  margin: 0 0 0 -8px;
}
