#tv-container {
  background-color: black;
  height: 100vh;
  box-sizing: border-box;
  color: white;

  background-color: black;
  display: flex;
  flex-direction: column;

  --text: white;
  --primary-text: white;

  --base-size: calc(100vw * 0.036);
  --main-text-size: calc(var(--base-size) * 1.125);
  --secondary-text-size: calc(var(--base-size) * 0.85);

  --margin-xl: calc(var(--margin-m) * 2); /* 32px */
  --margin-l: calc(var(--margin-m) * 1.5); /* 24px */
  --margin-m: var(--base-size); /* 16px */
  --margin-s: calc(var(--margin-m) / 4 * 3); /* 12px */
  --margin-xs: calc(var(--margin-m) / 2); /* 8px */
  --margin-xxs: calc(var(--margin-m) / 4); /* 4px */
  --stroke: 3px; /* 1px */

  padding: var(--margin-xs);
}

.toolbar {
  display: flex;
  gap: var(--margin-xxs);
  padding: 0 0 var(--margin-xs);
  justify-content: space-between;
  color: var(--secondary-text-white);
}

.toolbar.bottom {
  border-bottom: none;
  display: flex;
  padding: 0;
  justify-content: space-between;
}

.toolbar.top:after {
  content: '';
  position: absolute;
  height: var(--margin-s);
  margin-top: var(--margin-s);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  left: 0;
  width: 100%;
  z-index: 999;
}

.toolbar.bottom::before {
  content: '';
  position: absolute;
  height: var(--margin-s);
  margin-top: calc(-1 * var(--margin-s) + 1px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  left: 0;
  width: 100%;
  z-index: 999;
}

.toolbar.bottom .elements {
  display: flex;
  /* gap: var(--margin-xxs); */
}

.elements.left .button {
  margin-left: var(--margin-xxs);
}

.next-prev-buttons .button {
  margin: 0 !important;
}

.elements.right .button {
  margin-left: var(--margin-xxs);
}

.next-prev-buttons {
  display: flex;
}

.disabled.next-prev-buttons {
  opacity: 0.5;
}

.disabled .button {
  pointer-events: none;
}

.toolbar h5 {
  font-size: var(--margin-xs);
  letter-spacing: calc(var(--margin-xxs) / 5);
  font-weight: 500;
  line-height: var(--margin-xs);
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  float: none;
  text-transform: uppercase;
}

.container {
  flex: 1;
  overflow-y: auto;
  display: flex;
  align-items: center;
}

.content-wrapper {
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: var(--margin-xs); /* optical compensation */

  font-family: 'Overpass', 'Inter', sans-serif;
  letter-spacing: var(--stroke);
}

.button {
  font-size: calc(var(--margin-xs) / 1.5);
  padding: 0 var(--margin-xxs);
  border: var(--stroke) solid #333;
  border-radius: var(--margin-xxs);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: var(--margin-m);
  color: #999;
  position: relative;
}

.button.left {
  border-radius: var(--margin-xxs) 0 0 var(--margin-xxs);
}
.button.right {
  border-radius: 0 var(--margin-xxs) var(--margin-xxs) 0;
  border-left: none;
  margin-left: -1px;
}

.button svg {
  color: #999;
  transition: all 0.2s;
  width: var(--margin-xs);
  height: var(--margin-m);
  stroke-width: calc(var(--stroke) / 1.5);
}

.button .hotkey {
  font-size: calc(var(--margin-xxs) * 1.2);
  margin-left: var(--margin-xxs);
  color: #999;
  background-color: var(--divider-white);
  padding: 0 calc(var(--margin-xxs) / 1.5);
  height: var(--margin-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--margin-xxs) / 2);
}

.button:hover {
  background-color: #333;
  border-color: transparent;
}

.button:active {
  background-color: #333;
  border-color: transparent;
  padding-top: var(--stroke);
}

.button:active svg {
  color: white;
}

.workout-type {
  color: var(--secondary-text-white) !important;
  display: flex;
}

.workout-type .counter {
  min-width: var(--margin-l);
}

.conditioning .workout-type {
  color: var(--accent);
}

.strength .workout-type {
  color: var(--accent-red);
}

.skill .workout-type {
  color: var(--accent-yellow);
}

.date-picker {
  position: absolute !important;
  opacity: 0;
  outline: none;

  width: 100%;
  margin: 0;
  padding: 0;
}

.date-picker input {
  display: none;
}

.date-picker :global(.MuiInputAdornment-root),
.date-picker :global(.MuiInputBase-root) {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: none;
}

.date-picker button {
  height: 100%;
  background-color: red;
  border-radius: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* #tv-container input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */

.title {
  color: white;
}

.icon-label-left {
  margin-left: var(--margin-xxs);
}

.icon-label-right {
  margin-right: var(--margin-xxs);
}

#tv-container .placeholder {
  font-size: var(--margin-m);
  line-height: 150%;
  color: var(--secondary-text-white);
  text-align: center;
}
