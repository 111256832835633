.actions {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin: 24px 0 0 0;
  gap: 16px;
  border-bottom: 1px solid var(--divider);
  padding-bottom: 32px;
}

.actions div {
  flex: 1 0;
}

.placeholder {
  background-color: var(--bg-gray);
  color: var(--secondary-text);
  margin-top: 16px;

  border-radius: 4px;
  border-radius: 4px;
  padding: 28px;
  text-align: center;
  font: var(--small-text);
  font-style: italic;
}
