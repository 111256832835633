.sub-text,
.hint-text,
.error-text {
  font: var(--small-text);
}

.hint-text {
  color: var(--secondary-text);
}

.error-text {
  color: var(--accent-red);
}

/* .number-input {
  margin: 2px 0 !important;
} */

.number-input {
  position: relative;
}

.number-input input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.number-input input::-webkit-inner-spin-button,
.number-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input.hide-buttons input {
  border: 1px solid var(--divider-solid);
  width: 100%;
  border-radius: 4px;
}

.number-input.hide-buttons input:focus {
  border-bottom: 3px solid var(--accent);
  padding-top: 2px;
  outline-width: 0;
}

.number-input.hide-buttons button {
  display: none;
}

.number-input input {
  float: left;
  width: calc(100% - 72px);
  border-radius: 0;
  border-left: none;
  border-right: none;
  text-align: center;
}

.number-input input:hover:disabled {
  border-color: var(--divider-solid);
}
.number-input button {
  border: 1px solid var(--divider-solid);
  height: 48px;
  width: 36px;
  float: left;
  background: var(--bg-gray);
  cursor: pointer;
  border-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--accent);
}
.number-input button:first-child {
  border-left: 1px solid var(--divider-solid);
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.number-input button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.number-input button:hover {
  background: var(--accent);
  border-color: var(--accent);
  color: white;
}
.number-input button:active {
  background: var(--accent-clicked);
  padding-top: 1px;
  border: none;
  color: white;
}

.number-input button.disabled,
.number-input button.disabled:hover,
.number-input button.disabled:active {
  background: var(--bg-gray);
  cursor: default;
  color: var(--secondary-text);
  padding-top: 0 !important;
}
.number-input button.disabled:active {
  border: 1px solid var(--accent);
  border-left: none;
}

.number-input button:first-child.disabled:active {
  border: 1px solid var(--accent);
  border-right: none;
}

.number-input:hover button,
.number-input:hover input {
  border-color: var(--accent);
}

.number-input.disabled button,
.number-input.disabled button:hover {
  background-color: var(--bg-gray);
  border-color: var(--divider-solid);
}

.number-input.disabled input {
  border-color: var(--divider-solid);
  color: var(--secondary-text);
}

/** we show the value preview only */
.number-input:has(input:focus) .value-preview {
  display: none;
}

.value-preview {
  display: flex;
  position: absolute;
  width: calc(100% - 72px - 2px); /** to show the border of the input below */
  margin: 1px 0 0 37px;
  height: calc(48px - 2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-gray);
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 3px;
}

.value-preview:hover {
  border-color: 1px solid var(--accent);
}
