.title {
  display: flex;
}

.title:hover {
  color: var(--accent-hover);
}

.container {
  width: 100%;
}

.title h2 {
  margin-right: 12px;
  border-bottom: 1px dashed var(--accent) !important;
  float: left;
  height: 32px;
  padding: 0;
  line-height: 32px;
}

.edit-link {
  color: var(--accent-green);
  margin: 5px 20% -30px 0;
  cursor: pointer;
  clear: left;
}

.title:hover .edit-link {
  color: var(--accent-hover);
}

.title input {
  border: none;
  border-bottom: 1px solid var(--divider) !important;
  border-radius: 0;
  font: var(--h2);
  padding: 0;
  margin: 27px 0 0px;
  height: 32px;
  background: transparent;
  line-height: 32px;
  width: 100%;
}

.title input:focus {
  margin: 29px 0 -2px;
  border-bottom: 3px solid var(--accent) !important;
  padding-bottom: 2px;
}

.title.error input {
  border-color: var(--accent-red) !important;
}

.subheader-and-link {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
}

.subheader-and-link span {
  height: 24px;
}

.subheader-and-link .error {
  color: var(--accent-red);

  font: var(--h5);
  max-width: 50%;
  text-align: right;
}

.subheader-and-link h3 {
  width: auto !important;
  float: left;
}

.subheader-and-link .title-link {
  color: var(--accent-text);
  cursor: pointer;
  font: var(--h5);
}

.subheader-and-link .title-link:hover {
  color: var(--accent-hover);
}
