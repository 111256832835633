.move {
  flex: 1;
  width: 75%;
  overflow: hidden;
}

.superset.move {
  width: 60%;
}

.options-list {
  background-color: white;
  z-index: 999;
  position: absolute;
  border-radius: 4px;
  box-shadow: var(--shadow-base);
  width: 310px;
}

.move ul {
  list-style: none;
  max-height: 328px;
  width: 100% !important;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
}

.move li {
  padding: 12px 8px;
}

/*when "press esc" shown */
.move.with-key li:last-child {
  margin-bottom: 10px;
}

.move.with-key ul {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.move.short.with-key li:last-child {
  margin-bottom: 0;
}

.move .item {
  font: var(--h5);
  color: var(--secondary-text);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
  padding: 32px 9px 16px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0px,
    rgba(255, 255, 255, 1) 10px
  );
  margin: -20px 0 0 0;
  position: relative;
  height: 64px;
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

/*when there's not too many matches in the list of moves*/
.move.short .item {
  margin-top: 0;
  height: 40px;
  padding: 10px 9px;
  align-items: baseline;
}

.move li mark {
  background-color: var(--accent-highlight);
}

.move .item span {
  color: black;
}

.superset ul {
  width: calc(310px - 56px);
  margin-right: -56px;
}

.move input {
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  font-weight: 500;
}

.first input {
  border-bottom-left-radius: 0;
}

.middle input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.last input {
  border-top-left-radius: 0;
}
