.list {
  border: 1px solid var(--divider);
  display: flex;
  flex-direction: column;
  width: 320px;
  border-radius: 4px;
}

.track {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-bottom: 1px solid var(--divider);

  & > .name {
    display: flex;
    flex-direction: column;
  }
}

.track .top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.track:last-child {
  border-bottom: none;
}

.side-links {
  display: flex;
  gap: 4px;
}

.side-links div {
  text-align: right;
  width: 56px;
}

.s {
  font: var(--h6);
  color: var(--secondary-text);
  font-weight: 400;
}

.s.link {
  color: var(--accent-text);
  font-weight: 500;
}

.link:hover {
  cursor: pointer;
  color: var(--accent-hover);
}
