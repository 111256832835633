.gym-name {
  padding: 32px 0 16px;
  margin: 0 32px 0 34px;
  color: white;
  position: relative;
  font-weight: 600;
}

.gym-name div {
  font-weight: 600;
  float: left;
  position: relative;
}

.gym-name.multi {
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  margin: 24px 28px 0 26px;
  transition: 0.2s all;
}

.gym-name.multi svg {
  stroke: var(--icon-white);
  margin: 1px 0 0 4px;
}

.gym-name.multi:hover {
  background-color: var(--bg-button-white-hover);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.gym-name.multi:active {
  padding-top: 2px;
  height: 30px;
  background-color: var(--bg-darker-dark);
}

.gym-name.multi:hover svg {
  stroke: var(--accent);
}

.space-selector {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  opacity: 0;
  height: 32px;
}
