.bg {
  background-color: var(--bg-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 880px;
  background-color: white;
  margin: 0 auto;
  padding: 88px 0;
}

.container h1 {
  font-size: 40px;
}

.logo {
  width: 320px !important;
  margin: 40px 0 32px !important;
}

.logo img {
  width: 100%;
}

.text {
  width: 90%;
  font-size: 28px;
  line-height: 46px;
  text-align: center;
}

.text.ins {
  margin: 40px 0 16px;
  font-weight: 600;
}

.qr {
  width: 25%;
  display: block;
  margin-bottom: 40px;
  margin-bottom: 40px;
}

.code {
  width: 500px;
  margin-top: -8px;
  border: 2px solid var(--divider);
  text-transform: uppercase;
  font-size: 56px;
  line-height: 56px;
  font-family: 'Work Sans';
  text-align: center;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  letter-spacing: 10px;
  border-radius: 12px;
}

.link {
  font-size: 16px;
  margin-top: 16px;
}
