.secondary-nav {
  display: flex;
  /*font-size: calc(16px + (26 - 16) * ((100% - 300px) / (1600 - 300)));*/
  width: 100%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 104px 24px 28px;
  background-color: var(--bg-dark);
  color: white;
  /* z-index: 99; */
  justify-content: space-between;
  height: 72px;
  --top-nav-height: 134px;
}

.secondary-nav.calendarWithTracks {
  height: 92px;
  --top-nav-height: 154px;
}

.arrows {
  width: 64px;
  margin: 0 16px 0 -4px;
}

.arrows div {
  float: left;
}

.secondary-nav h2 {
  margin-left: 76px;
}

.month-title {
  margin-left: 0 !important;
}

.button-group {
  display: flex;
  gap: 12px;
}
