.sidebar {
  width: 448px;
  height: 100vh;
  top: 0;
  right: -448px;
  z-index: 2000;
  position: fixed;
  transition: right 0.2s;
  margin-left: -3px;

  background-color: white;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
}

.close-icon {
  color: var(--accent);
  position: absolute;
  right: 24px;
  top: 28px;
}
.close-icon:hover {
  color: var(--accent-hover);
  cursor: pointer;
}
.sidebar.active {
  right: 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
}

#content {
  /* margin-top: 4px; */
  padding: 0 24px 24px;
  flex: 1;
  min-height: 480px;
  overflow-y: scroll;
}

.only-buttons {
  /* margin-top: 4px !important; */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar header {
  padding: 28px 24px 16px;
  box-sizing: border-box;
  height: 132px;
  position: relative;
}

#gradient-bar {
  display: none;
  float: left;
  width: 8px;
  height: 100%;
  float: left;
  /*background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(196, 196, 196, 0) 100%);*/
}

/* .sidebar.edit h2,
.sidebar.add h2 {
  border-bottom: 2px dotted var(--divider);
  float: left;
  height: 32px;
} */

.sidebar h2 {
  margin-top: 26px;
}

.sidebar.edit h2,
.sidebar.add h2 {
  float: left;
  margin-top: 30px;
  margin-bottom: -5px;
  line-height: 30px;
}

.subheader {
  font: var(--p);
  height: 24px;
}

.placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text);
}

.scroll-shadow {
  background: radial-gradient(50% 16px at top, rgba(0, 0, 0, 0.1), transparent);
  width: 100%;
  height: 16px;
  position: absolute;
  z-index: 999;
  margin-left: 50%;
  left: -50%;
  bottom: -16px;
  pointer-events: none;
  transition: opacity 0.2s;
  opacity: 0;
}

.scroll-shadow.visible {
  opacity: 1;
}

.staff-message {
  display: flex;
  gap: 12px;
  padding: 20px 16px;
  background-color: var(--bg-gray);
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

.staff-message svg {
  position: relative;
  top: -1px;
  opacity: 0.45;
}

.staff-message h4 {
  margin: 0 0 0px;
  color: var(--secondary-text);
  font: var(--h6);
  letter-spacing: 1px;
}

.staff-message span {
  font: var(--h5);
}

.cycles-sidebar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  height: calc(100%);
}

.cycles-sidebar section {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cycles-sidebar section:first-child {
  padding-top: 0;
}

.divider {
  border-top: 1px solid var(--divider);
  margin: 24px 0;
}
