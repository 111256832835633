.container {
  position: relative;
  --element-height: 40px;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--divider-solid);
  box-sizing: border-box;
  cursor: text;
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
}

.error .inputContainer,
.error .inputContainer:has(input:focus) {
  border-color: var(--accent-red);
}

.inputContainer:hover {
  border: 1px solid var(--accent);
}

.inputContainer:has(input:focus) {
  border-bottom: 3px solid var(--accent);
  padding-bottom: 2px;
  outline-width: 0;
}

.input {
  border: none;
  flex: 1;
  min-width: 50px;
  height: var(--element-height);
}

.menuItem {
  padding: 8px;
  cursor: pointer;
}

.highlighted {
  background-color: #bde4ff;
}

.tag {
  display: flex;
  align-items: center;
  height: var(--element-height);
  padding: 0 6px 0 8px;
  background-color: var(--accent-highlight);
  border-radius: 4px;
}

.tag svg {
  opacity: 0.5;
}

.tag svg:hover {
  opacity: 1;
}

.removeIcon {
  margin-left: 4px;
  cursor: pointer;
}

.options-list {
  background-color: white;
  z-index: 999;
  position: absolute;
  border-radius: 4px;
  box-shadow: var(--shadow-base);
  width: 310px;
}

.options-list ul {
  list-style: none;
  max-height: 328px;
  width: 100% !important;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
}

.options-list li {
  padding: 12px 8px;
}

.options-list li mark {
  background-color: var(--accent-highlight);
}

.input {
  padding: 8px;
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
}
