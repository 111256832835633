.avatar {
  aspect-ratio: 1/1;
  background-color: #9eafac;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: white;
  overflow: hidden;
  /* box-shadow: inset 0 0 0px 1px rgba(0, 0, 0, 0.2); */
}

.alert-modal :global(.alert-title) {
  --ion-text-color: black;
}
.alert-modal :global(.alert-message) {
  --ion-text-color: var(--secondary-text);
}
.alert-modal :global(.alert-button) {
  --ion-text-color-rgb: 0, 0, 0 !important;
}
