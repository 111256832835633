.user-list {
  margin: -12px;
  min-height: 80px;
}

.user-list h3 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.user-list p {
  margin-bottom: 16px;
  display: block;
  max-width: 480px;
  color: var(--secondary-text);
}

.user-list li {
  padding: 12px;
  font-size: 20px;
}
.user-list li:last-child {
  margin-bottom: 0;
}

.user-list.single li {
  border: none;
  padding: 16px 0;
  margin-bottom: -8px;
}
.user-list li span {
  color: var(--secondary-text);
}
