.workout {
  box-sizing: border-box;
  padding: 4px 0 4px 2px;
  transition: opacity 0.2s;
  opacity: 1;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

/** original item that is being dragged */
.workout.dragging {
  opacity: 0.7;
  border: 1px dashed var(--divider);
  border-color: green;
}

.workout.hovering {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: var(--shadow-elevated), 0 0px 2px rgba(0, 0, 0, 0.3),
    0 4px 10px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(4px);
  cursor: move;
}

.hoverhint {
  display: none;
  border-radius: 4px;
  pointer-events: none;
  font: var(--calendar-items-text);
  font-size: 10px;
  text-align: right;
  padding: 2px 4px;
  margin: 2px 0 0 0px;
  color: var(--secondary-text);
  position: relative;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(1px);
  float: right;
}

.hoverhint.hovering {
  display: block;
}

.hoverhint.duplicating {
  background-color: var(--accent);
  color: white;
}

.workout:hover:not(.disable-drag) {
  box-shadow: var(--shadow-base), 0 0px 2px rgba(0, 0, 0, 0.3);
}

.workout:first-child .workout-title,
.workout:first-child .draft-title {
  padding-top: 0;
  margin-top: 0;
}

.workout-title,
.draft-title {
  width: 100%;
  font: var(--calendar-items-text);
  margin-bottom: 6px;
  padding: 0 0 0;
  box-sizing: border-box;
  color: var(--secondary-text);
}

.draft-title {
  font-weight: 500;
  flex: 1;
  margin: 0 0 0 2px;
}

:global(.highlight-drafts) .workout.published {
  opacity: 0.2;
}

.day-item,
.note-item {
  width: 100%;
  font: var(--calendar-items-text);
  padding: 0 0px;
  box-sizing: border-box;
  margin-bottom: 6px;
  display: flex;
}

.day-item.note {
  white-space: pre-line;
  margin-left: 2px;
}

.day-item .icon {
  width: 8px;
  height: 8px;
  float: left;
  margin: 1px 6px 0 0;
}

.day-item:last-child {
  margin-bottom: 0;
}

.movement-name {
  float: left;
  width: calc(100% - 14px);
}

.note-item {
  display: flex;
  padding-right: 4px;
  gap: 3px;
  color: var(--secondary-text);
  margin-bottom: 0;
  max-height: 28px;
  overflow: hidden;
}
