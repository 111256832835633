.buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
}

.buttons .row {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--divider-solid);
  height: 84px;
  border-radius: 8px;
  padding: 4px 0 0px;
  gap: 6px;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  font: var(--h6);
  max-width: calc((100% - 16px) / 3);
}

.button:hover {
  background-color: var(--bg-gray);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--divider-solid);
}
