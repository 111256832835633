.button {
  width: 100%;
  height: 48px;
  background: var(--accent);
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  font: var(--button);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  flex-shrink: 0;
  gap: 16px;
}

.button.disabled,
.button.disabled:hover,
.button.disabled:active {
  background-color: var(--bg-gray);
  color: var(--placeholder-text);
  box-shadow: none;
  padding-top: 0;
  cursor: default;
}

.button:hover {
  background-color: var(--accent-hover);

  box-shadow: 0 1px 1px 0px rgba(36, 139, 83, 0.8);
  color: white;
  border: 0;
}
.button:active {
  background-color: var(--accent-clicked);
  padding-top: 2px;
  box-shadow: none;
}

.button.secondary {
  background-color: white;
  color: black;
  border: 1px solid var(--divider);
  box-shadow: none;
}

.button.secondary.disabled,
.button.secondary.disabled:hover {
  background-color: white;
  border: 1px solid #dedede;
  color: var(--secondary-text);
}

.button.destructive {
  background-color: var(--accent-red);
}

.button.destructive:hover {
  background-color: var(--accent-red-hover);
}

.button.destructive:active {
  background-color: var(--accent-red-clicked);
}

.button.secondary:hover {
  color: white;

  box-shadow: 0 1px 1px 0px rgba(36, 139, 83, 0.8);
  border-color: var(--accent-hover);
  background-color: var(--accent-hover);
}

.button.secondary:active {
  border-color: var(--accent-clicked);
  background-color: var(--accent-clicked);
}

.button.secondary:hover svg {
  stroke: white;
}

.button.tertiary {
  background-color: white;
  color: var(--accent-text);
  border: none;
}

.tertiary-destructive {
  color: var(--accent-red);
  background-color: white;
  box-shadow: none;
  height: 32px;
}

.tertiary-destructive:hover {
  background-color: var(--accent-red-hover);
}

.tertiary-destructive:active {
  background-color: var(--accent-red-clicked);
}

.login__google {
  background-color: #4285f4;
}

.login__google:hover {
  background-color: #4f92ff;
}

.login__google:active {
  background-color: #286ddc;
}

.secondary-dark {
  background-color: transparent;
  border: 2px solid var(--accent);
}
