.progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  transition: all 0.5s;
  position: relative;
}

@media only screen and (max-width: 720px) {
  .progress-bar-container {
    display: none;
  }
}
.progress-bar-container.step3 {
  opacity: 0;
  margin-top: -64px;
}

.progress-bar {
  display: flex;
}

.progress-bar .item {
  font: var(--small-text);
  color: var(--secondary-text-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 148px;
  gap: 8px;
  transition: all 0.5s;
  z-index: 2;
}

.progress-bar .item.active {
  color: white;
}

.progress-bar .item div {
  width: 7px;
  height: 7px;
  background-color: #444;
  border-radius: 8px;
  transform: all 0.5s;
}

.progress-bar .item.active div {
  background-color: white;
  width: 9px;
  height: 9px;
  margin-top: -2px;
}

.progress-bar-container .line {
  width: 296px;
  height: 1px;
  position: relative;
  top: -4px;
  background-color: #333;
}

.progress-bar-container .line .dot {
  width: 4px;
  height: 5px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  top: -2px;
  margin-left: 0;
  transition: all 0.5s;
  opacity: 1;
}
.progress-bar-container.step15 .line .dot {
  margin-left: calc(25% - 4.5px);
  width: 5px;
  opacity: 1;
}

.progress-bar-container.step2 .line .dot {
  margin-left: calc(50% - 4.5px);
  width: 9px;
  opacity: 1;
}

.progress-bar-container.step3 .line .dot {
  margin-left: calc(100% - 4.5px);
  width: 9px;
  opacity: 1;
}

.progress-bar-container .line .fill {
  transition: width 0.5s;
  width: 0%;
  height: 1px;
  background-color: white;
}

.progress-bar-container.step15 .line .fill {
  width: 25%;
}
.progress-bar-container.step2 .line .fill {
  width: 50%;
}

.progress-bar-container.step3 .line .fill {
  width: 100%;
}
