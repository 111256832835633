@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Overpass:wght@300;400;500;600&display=swap');
/* #### Generated By: http://www.cufonfonts.com #### */

* {
  padding: 0;
  margin: 0;
}

:root {
  /* Colors */
  --primary-text: rgb(0, 0, 0);
  --primary-text-white: rgb(255, 255, 255);
  --accent: #1cb865;
  --accent-text: #319e63;
  --accent-hover: #27c471;
  --accent-highlight: #b6f4d3;
  --accent-clicked: #008940;
  --accent-darker: #137f45;
  --secondary-text: rgba(0, 0, 0, 0.65);
  --secondary-text-white: rgba(255, 255, 255, 0.5);
  --placeholder-text: rgba(0, 0, 0, 0.35);
  --divider: rgba(0, 0, 0, 0.2);
  --divider-white: rgba(255, 255, 255, 0.2);
  --divider-solid: #c5d3cb;
  --icon-white: rgba(255, 255, 255, 0.35);
  --icons-on-dark: rgba(79, 79, 79, 0.7);
  --icon: #9e9e9e;

  --accent-red: #ff5959;
  --accent-red-hover: #ff7070;
  --accent-red-highlight: #ffbdbd;
  --accent-orange: #ff9c54;
  --accent-blue: #5f6fff;
  --accent-blue-highlight: #c9ceff;
  --accent-red-clicked: #b00e0e;
  --accent-green: #319e63;

  --accent-yellow: #ffc400;
  --accent-yellow-hover: #ffcf00;
  --accent-yellow-dark: #ff9c00;
  --accent-yellow-darker: #916928;
  --accent-yellow-highlight: #ffeeb7;
  --accent-yellow-highlight-lighter: #fffbec;

  --color-cycle-green: var(--accent-highlight);
  --color-cycle-blue: var(--accent-blue-highlight);
  --color-cycle-yellow: var(--accent-yellow-highlight);
  --color-cycle-red: var(--accent-red-highlight);

  --bg-light-gray: rgba(0, 0, 0, 0.025);
  --bg-gray: #f4f9f6;

  --bg-dark: #333333;
  --bg-darker-dark: rgb(35, 35, 35);

  --bg-button-white-hover: rgba(255, 255, 255, 0.08);

  /* Text Styles */

  --base: 400 16px/24px 'Inter', sans-serif;
  --calendar-items-text: 400 12px/14px 'Inter', sans-serif;

  --h1: 600 36px/42px 'Work Sans', sans-serif;
  --h2: 600 28px/32px 'Work Sans', sans-serif;
  --h2-light: 400 28px/32px 'Work Sans', sans-serif;
  --h3: 600 24px/24px 'Work Sans', sans-serif;
  --h3-light: 400 24px/24px 'Work Sans', sans-serif;

  --h4: 500 18px/24px 'Inter', sans-serif;
  --p: 400 16px/24px 'Inter', sans-serif;
  --h5: 400 14px/16px 'Inter', sans-serif; /* main nav links */
  --h6: 500 12px/16px 'Inter', sans-serif; /* small button labels */

  /* --h1: 600 28px/32px 'Work Sans', sans-serif;
  --h1-light: 400 28px/32px 'Work Sans', sans-serif;

  --h2: 400 18px/24px 'Work Sans', sans-serif;
  --h3: 600 16px/24px 'Work Sans', sans-serif; */

  --button: 600 12px/16px 'Inter', sans-serif;

  --shadow-base: 0px 3px 3px rgba(0, 0, 0, 0.09);
  --shadow-elevated: 0px 4px 8px rgba(0, 0, 0, 0.15);

  --small-text: 400 12px/20px 'Inter', sans-serif;

  --min-width: 960px;
  --top-nav-height: 134px;
}

.top-error {
  --top-nav-height: calc(134px + 32px);
}

@media only screen and (max-width: 720px) {
  :root {
    --h1: 600 30px/36px 'Work Sans', sans-serif;
    --h2: 600 22px/26px 'Work Sans', sans-serif;
    --h2-light: 400 22px/26px 'Work Sans', sans-serif;
    --h3: 500 18px/24px 'Work Sans', sans-serif;
    --h3-light: 400 20px/24px 'Work Sans', sans-serif;

    --h4: 500 18px/24px 'Inter', sans-serif;
    --p: 400 16px/24px 'Inter', sans-serif;
    --h5: 400 14px/16px 'Inter', sans-serif; /* main nav links */
    --h6: 600 12px/16px 'Inter', sans-serif; /* small button labels */

    --min-width: auto;
  }
}

html {
  height: 100%;
  overflow: hidden;
  position: relative;
}

body {
  font: var(--base);
  overflow-y: hidden;
  height: 100%;
  /* min-width: var(--min-width); -- without this content won't scroll horizontally */

  background-color: #333;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: var(--min-width);
}

#outlet {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* #content {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: scroll;
} */

h1 {
  font: var(--h1);
}

h2 {
  font: var(--h2);
}
h2 .light {
  font: var(--h2-light);
}
h3 {
  font: var(--h3);
}
h3.light {
  font: var(--h3-light);
}

h4 {
  font: var(--h4);
  text-transform: uppercase;
  letter-spacing: 2px;
}

h5 {
  font: var(--h5);
  text-transform: capitalize;
}

h6 {
  font: var(--h6);
  text-transform: uppercase;
  letter-spacing: 1px;
}

a,
span.link {
  color: var(--accent-text);
  text-decoration: none;
  cursor: pointer;
}

span.link.s {
  font-size: 12px;
  font-weight: 600;
}

a:hover,
span.link:hover {
  color: var(--accent-hover);
}

p.caption {
  font-size: 14px;
  color: var(--secondary-text);
}

label {
  display: block;
  font: var(--small-text);
  font-weight: 500;
  margin-top: 16px;
}

input,
.date-picker input,
select,
textarea {
  font: var(--base);
}

/* textarea {
  padding: 12px 4px;
} */

input,
textarea,
.date-picker,
.range-picker,
select {
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  border: 1px solid var(--divider-solid);
  border-radius: 4px;
  background-color: white;
  outline: none;
}

.date-picker {
  border: 1px solid var(--divider-solid) !important;
}

.date-picker input,
.date-picker fieldset {
  border: none;
  border-radius: 0;
}

.date-picker.error,
.date-picker.error:hover,
.date-picker.error:has(:focus) {
  border-color: var(--accent-red) !important;
}

::placeholder {
  color: var(--placeholder-text);
}

.range-picker {
  background-color: var(--bg-gray) !important;
  font-size: 16px;
}

.range-picker input {
  font-size: 16px !important;
}

.ant-picker-dropdown {
  z-index: 9999999;
}

.ant-picker-cell-week {
  font-size: 10px;
}

/* Start/end of the selected range*/
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-mzwlov).ant-picker-range
  .ant-picker-active-bar {
  background: var(--accent) !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-picker-range
  .ant-picker-active-bar {
  height: 3px;
}

/* Selected range color*/
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
  background: var(--accent-highlight) !important;
}

/* Border around today's date */
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--divider);
}

/* calendar icon */
.ant-picker-suffix {
  right: 4px;
  position: relative;
  color: var(--icon);
}

input,
.date-picker input,
.range-picker,
select {
  height: 48px;
  padding: 0 8px;
}

/*removing safari gloss, adding arrow */
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjI5Mjg5IDUuMjkyODlDMi42ODM0MiA0LjkwMjM3IDMuMzE2NTggNC45MDIzNyAzLjcwNzExIDUuMjkyODlMOCA5LjU4NTc5TDEyLjI5MjkgNS4yOTI4OUMxMi42ODM0IDQuOTAyMzcgMTMuMzE2NiA0LjkwMjM3IDEzLjcwNzEgNS4yOTI4OUMxNC4wOTc2IDUuNjgzNDIgMTQuMDk3NiA2LjMxNjU4IDEzLjcwNzEgNi43MDcxMUw4LjcwNzExIDExLjcwNzFDOC4zMTY1OCAxMi4wOTc2IDcuNjgzNDIgMTIuMDk3NiA3LjI5Mjg5IDExLjcwNzFMMi4yOTI4OSA2LjcwNzExQzEuOTAyMzcgNi4zMTY1OCAxLjkwMjM3IDUuNjgzNDIgMi4yOTI4OSA1LjI5Mjg5WiIgZmlsbD0iIzMxOUU2MyIvPgo8L3N2Zz4K');
}

input:hover,
.date-picker:hover,
textarea:hover,
.range-picker:hover,
.ant-picker-focused,
.ant-picker-active,
.range-picker:active,
select:hover {
  border-color: var(--accent-hover) !important;
}

input:focus,
select:focus,
range-picker:focus {
  border-bottom: 3px solid var(--accent);
  padding-top: 2px;
  outline-width: 0;
}

.date-picker:has(input:focus) {
  border-bottom: 3px solid var(--accent) !important;
}

.date-picker input:focus {
  border-bottom: 0;
  height: 44px;
  border: none;
}

input[type='text'].error,
input[type='number'].error {
  border-color: var(--accent-red) !important;
}

textarea.error {
  border-color: var(--accent-red) !important;
}

textarea {
  padding: 8px 8px;
}

textarea:focus {
  padding: 8px 8px 6px;
  border-bottom: 3px solid var(--accent);
  outline-width: 0;
}

label.checkbox:focus {
  border: 1px solid var(--accent);
}

label.checkbox > * {
  float: left;
}
label.checkbox span {
  font: var(--base);
  float: left;
  margin: -4px 0 0 8px;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  padding: 0;
}

input:disabled {
  background-color: var(--bg-gray);
  color: var(--secondary-text);
  border-color: var(--divider-solid);
}

ul,
li {
  list-style: none;
}

input.accessCode {
  text-transform: uppercase;
  font: var(--h2-light);
  height: 80px;
  letter-spacing: 8px;
  text-align: center;
}

#toolbar .item {
  float: left;
  margin-right: 16px;
}
#toolbar .item input[type='radio'] {
  margin: 6px 8px 0 0;
  float: left;
}
#toolbar {
  margin-bottom: 24px;
  display: flex;
}

#admin button {
  padding: 8px;
  margin-top: -4px;
  float: right;
}

#outlet {
  background-color: var(--bg-gray);
  position: relative;
  overflow: hidden;
}

#content {
  overflow: scroll;
}

#container {
  padding: 0 104px;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#page-content {
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

#page-content h3 {
  margin: 40px 0 24px;
}

#page-content.no-subtitle {
  padding-top: 24px;
}

#page-content.col-8 {
  max-width: 816px;
  width: 100%;

  margin: 0 auto;
}

#page-content section {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
}

#page-content section section {
  padding: 0;
  box-shadow: none;
}

#page-content .col-5 {
  width: calc(((100% - 16px * 7) / 8) * 5 + 4 * 16px);
}

#page-content .col-6 {
  width: calc(((100% - 16px * 7) / 8) * 6 + 5 * 16px);
}

#page-content .col-3 {
  width: calc(((100% - 16px * 7) / 8) * 3 + 2 * 16px);
}

#page-content .col-2 {
  width: calc(((100% - 16px * 7) / 8) * 2 + 1 * 16px);
}
#page-content .left {
  float: left;
}

#page-content .right {
  float: right;
}

.page-section h3 {
  margin-bottom: 8px;
}

.page-section h4 {
  border-bottom: 1px solid var(--divider);
  padding-bottom: 20px;
}

div.placeholder {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  width: 75%;
  margin: 0 auto;

  color: #3e3e3e;
}

.modal-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.modal-s {
  box-sizing: border-box;
  width: 416px;
  padding: 32px;

  background: #ffffff;
  border-radius: 8px;
}

.modal-s header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.modal-s header .close {
  color: var(--accent);
  cursor: pointer;
}

.modal-s header .close:hover {
  color: var(--accent-hover);
}

strong {
  font-weight: 600;
}

footer {
  margin-top: 40px;
  padding: 20px 0;
  font-size: 12px;
  display: flex;
  border-top: 1px solid var(--divider);
  color: var(--secondary-text);
}

footer a {
  color: var(--secondary-text);
  text-decoration: underline;
}

footer ul li {
  float: left;
  margin-left: 16px;
}

body .react-tooltip {
  font-size: 12px;
  padding: 8px 10px;
  z-index: 100000;

  max-width: 240px;
  line-height: 16px;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.85);
}

.tooltip-hotkey {
  border-radius: 2px;
  background-color: #000;
  padding: 2px 4px;
  font-size: 10px;
  margin-left: 2px;
}

.tooltip.small {
  border-radius: 4px;
  pointer-events: none;
  font: var(--calendar-items-text);
  font-size: 10px;
  text-align: right;
  padding: 2px 4px;
}

.tooltip.big {
  border-radius: 8px;
  width: 260px;
  padding: 16px 20px;
  font: var(--h6);
}

.tooltip.big h1 {
  font: var(--h4);
  font-weight: 600;
  margin: 0 0 4px;
  padding: 0;
}

.tooltip.big p {
  margin: 0;
  padding: 0;
}

.tooltip.big h2 {
  font: var(--h5);
  line-height: auto;
  font-weight: 600;
  margin: 16px 0 8px;
  padding: 0;
}
