.note-card {
  box-sizing: border-box;
  color: var(--primary-text);

  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  box-shadow: var(--shadow-base);
  padding: 28px 24px 24px 40px;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.buttons .section {
  display: flex;
  gap: 8px;
}

.text {
  margin-top: 8px;
  color: var(--secondary-text);
  font: var(--h5);
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
}

.description {
  white-space: pre-wrap;
  transition: max-height 0.3s;
  position: relative;
  max-height: 80px;
  overflow: hidden;
}

/* .description::after {
  content: '';
  display: block;
  height: 48px;
  background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: opacity 0.5s;
  opacity: 1;
  pointer-events: none;
} */

.description.expanded {
  max-height: 800px;
  overflow: auto;
}

.description.expanded::after {
  opacity: 0;
}

.show-more {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  color: var(--accent-text);
  font: var(--h5);
  cursor: pointer;
}

.show-more:hover {
  color: var(--accent-hover);
}

.drag-icon {
  float: right;
  margin: -2px 0 0 12px;
  cursor: ns-resize;
}

.drag-icon svg {
  stroke: #ccc;
}

.drag-icon:hover svg {
  stroke: var(--accent);
}

.note-card:has(.drag-icon:hover) {
  box-shadow: var(--shadow-elevated);
}

.header {
  display: flex;
  justify-content: space-between;
  height: 48px;
  color: var(--secondary-text);
}

.category {
  margin-bottom: 8px;
  float: left;
}

.hide {
  visibility: hidden;
}
