.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--divider);
  border-radius: 8px;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.label {
  font: var(--h4);
}

.icon {
  margin-bottom: 12px;
  color: var(--accent-text);
}

.button:hover {
  border-color: var(--accent-hover);
  color: var(--accent-hover);
}

.button:active {
  padding-top: 2px;
}

.container:hover {
  fill: var(--accent);
  border-style: solid;
}

.container svg,
.container svg {
  /* fill: var(--divider);
  stroke: var(--divider); */
  transition: all 0.2s ease-in-out;
}

.container .icon-container {
  transition: all 0.2s;
  transform: scale(0.8) translateY(4px);
}

.container:hover .icon-container {
  transform: scale(0.85);
}

/* .hover svg rect,
.hover svg circle {
  fill: var(--accent);
  stroke: var(--accent);
} */
