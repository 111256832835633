#logo {
  width: 38px;
  height: 60px;
  background-color: #16db70;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  flex-direction: column;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  position: relative;
}

#logo img {
  height: 38px;
  text-align: center;
}

#logo-full {
  width: 50%;
  margin: 0 0 20px 0;
  padding-top: 8px;
  color: var(--accent);
}

#logo-full img {
  width: 100%;
}
