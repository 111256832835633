body {
  padding: 0;
}

.auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.auth input {
  border-color: white;
}
.auth input:hover {
  border-color: var(--accent-hover);
}

.auth input:focus {
  border-bottom: 3px solid var(--accent);
}
.auth_container {
  color: var(--secondary-text-white);
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: none;
  padding: 32px;
  border-radius: 4px;
  width: 320px;
  margin: 0 auto 48px;
}
.login__textBox {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.password__textBox {
  border-top: 1px solid var(--divider) !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}

.auth a {
  color: var(--secondary-text-white);
  text-decoration: underline;
}

.auth a:hover {
  color: var(--accent-hover);
}

.auth .welcome-msg {
  color: var(--secondary-text-white);
  text-align: left;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.auth .welcome-msg span {
  color: white;
  font: var(--h2-light);
  padding: 4px 0;
  display: block;
}

.under-input-message {
  margin-top: 8px;
  color: var(--primary-text-white);
}

.under-input-message.error {
  color: var(--accent-red);
}

.module h2 {
  text-align: left;
  width: 100%;
  color: white;
  margin-bottom: 24px;
  display: block;
}

.module input {
  background: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.8) !important;
  color: white;
}

.module input:focus {
  border-color: var(--accent) !important;
}

.module {
  --placeholder-text: rgba(255, 255, 255, 0.3);
}

.module p {
  color: white;
  text-align: left;
  margin-bottom: 24px;
}

.gym-name input {
  max-width: 400px;
  width: 100%;
  height: 80px;
  font-size: 32px;
  padding: 0 24px;

  margin: 16px 0;
}

.auth_container.gym-name {
  width: 400px;
}

.gym-name h2 span {
  display: block;
  margin-bottom: 16px;
  font: var(--h3);
}

.pricing h1 {
  text-align: center;
}

.pricing-options {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  z-index: 999;
}
