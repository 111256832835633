.everything {
  margin-bottom: 8px;
}

.overlay {
  width: 100%;
  height: 48px;
  margin-top: -48px;
  position: relative;
  cursor: grabbing;
  display: none;
}

.drag-overlay {
  position: relative;
  z-index: 100;
  scale: 1.05;
}

.drag-overlay .overlay {
  display: block;
  cursor: ns-resize;
}

.labels {
  height: 24px;
  margin-bottom: 12px;
  font: var(--small-text);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.labels .toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.drag-overlay .labels {
  opacity: 0;
}

.drag-overlay .inputs-and-icon-container {
  box-shadow: var(--shadow-elevated);
}

.drag-icon {
  width: 24px;
  margin-left: -24px;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.everything:hover .drag-icon,
.drag-overlay .drag-icon,
.sorting .drag-icon {
  opacity: 1;
}

.drag-icon svg {
  transform: scaleX(0.64);
  stroke: #ccc;
  margin-top: 14px;
}

.drag-icon:hover svg,
.drag-overlay .drag-icon svg {
  stroke: var(--accent);
}

.hide {
  opacity: 0;
}

.inputs-container {
  width: 100%;
  border-radius: 4px;
  display: flex;
  float: left;
  background-color: white;
  margin-right: -34px;
  margin-bottom: -2px; /*textarea-autoresize forces 50px height. this brings height to 48px as other fields*/
}

.inputs-and-icon-container.first {
  margin-bottom: -9px;
}

.inputs-and-icon-container.middle {
  margin-bottom: -9px;
}

.inputs-and-icon-container.last {
  margin-top: -5px;
}

.inputs-and-icon-container.first textarea {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.inputs-and-icon-container.middle textarea {
  border-radius: 0px;
}
.inputs-and-icon-container.last textarea {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.inputs-and-icon-container {
  display: flex;
  border-radius: 4px;
  width: 100%;
}

.inputs-and-icon-container:hover textarea,
.inputs-and-icon-container:hover .icon-container {
  border-color: var(--accent-hover);
  z-index: 9999;
}

.staff-note .inputs-and-icon-container:hover textarea {
  border-color: var(--accent-yellow) !important;
}

.everything:has(.drag-icon:hover) textarea {
  box-shadow: var(--shadow-base);
}

.note-input {
  font-style: normal;
  float: left;
  background-color: none;
  min-height: 48px;
  padding: 10px 48px 10px 8px;
  border: 1px dashed var(--divider-solid);
  overflow-y: hidden;
  resize: none !important;
}

.note-input:focus {
  padding: 10px 48px 8px 8px;
}

.icon-container {
  color: var(--accent-text);
  cursor: pointer;
  padding: 0 8px 0;
  margin-top: 12px;
  height: 24px;
  display: flex;
  float: right;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: none;
  box-sizing: border-box;
}

.staff-note .icon-container {
  color: var(--accent-yellow-darker);
}

.staff-note .icon-container:hover {
  color: var(--accent-yellow-hover);
}

.icon-container:hover {
  color: var(--accent-hover);
  /*background-color:var(--bg-gray);*/
}

.staff-note textarea {
  background-color: var(--accent-yellow-highlight-lighter) !important;
}

.staff-note .note-input {
  border: 1px dashed var(--accent-yellow);
}

.staff-note textarea:focus {
  border-bottom: 3px solid var(--accent-yellow);
}
