#calendar {
  width: 100%;
  transition: width 0.2s;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  background-color: white;
  height: 100%;
  /* height: calc(100vh - var(--top-nav-height)); 72px and 64px are headers */

  --weekday-labels-height: 44px;

  --row-min-height: 120px;
  --row-height: max(
    var(--row-min-height),
    calc(
        (
          (100vh - var(--top-nav-height) - var(--weekday-labels-height) - 52px) /
            5
        )
      )
      /* 50px to display part of a bottom week to hint that user can scroll */
  );

  --parent-height: calc(var(--row-height) * 4);
}

#calendar .five-weeks {
  --parent-height: calc(var(--row-height) * 5);
}

#calendar.sidebar {
  width: calc(100vw - 448px); /* area where the calendar scrolls*/
  position: fixed;
  /* top: var(--top-nav-height); */
}

#calendar .gym {
  width: 160px;
  margin: 12px 8px 0 0;
  height: 40px;
}

#calendar header {
  display: flex;
  /*font-size: calc(16px + (26 - 16) * ((100% - 300px) / (1600 - 300)));*/
  height: auto;
}

.arrows {
  width: 64px;
  margin: 0 16px 0 -4px;
}

.arr {
  cursor: pointer;
  color: var(--accent);
  box-sizing: border-box;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}

.arr:hover {
  color: var(--accent-hover);
  background-color: rgba(255, 255, 255, 0.1);
}

.arr:active {
  background-color: var(--bg-darker-dark);
  padding-top: 2px;
}

.today-button {
  cursor: pointer;
  color: var(--accent);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 7px 0 0 12px;
}

.today-button:hover {
  color: var(--accent-hover);
}
.today-button span {
  font-size: 24px;
  line-height: 1px;
  top: 3px;
  position: relative;
  margin-right: 3px;
}

.month-and-weekdays-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  /* height: 100%; */
  min-width: 768px;
  flex: 1 0;
}

.months-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  scroll-snap-type: y proximity;
  flex-grow: 1;
  display: flex;

  overflow-anchor: none; /** scroll acnhoring doesn't work on Safari, so I need to disable in Chrome too*/
}

.month {
  position: relative;
}

.days-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: var(--row-height);
  /* scroll-snap-type: mandatory;
  scroll-snap-align: start; */
  height: var(--parent-height);
  position: relative;
}

.five-weeks .days-container {
  grid-auto-rows: var(--row-height);
}

.is-expanded .months-scroll {
  display: block;
}

.is-expanded .days-container {
  height: auto;
  min-height: var(--parent-height);
  grid-auto-rows: minmax(max-content, var(--row-height)) !important;
}

#top-nav {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 28px 24px;
  background-color: var(--bg-dark);
  color: white;
}

#weekdays {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 14px;
  min-width: 768px;
  border-bottom: 1px solid var(--divider);
  padding-top: 20px;
  flex: 1 0;
}
.weekday {
  text-align: right;
  padding: 0 9px;
  color: var(--secondary-text);
}

.month-name {
  font: var(--h3);
  font-weight: 400;
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 12px 0 65px 12px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.4s;
  opacity: 0;
  width: calc((100% / 7) - 18px);
}

.month-name span {
  font-weight: 600;
}

.show-month-name .month-name {
  opacity: 1;
}
