.text-input {
  position: relative;
}

.sub-text,
.hint-text,
.error-text {
  font: var(--small-text);
}

.hint-text {
  color: var(--secondary-text);
}

.error-text {
  color: var(--accent-red);
}

.input.centered {
  text-align: center;
}

.text-input:has(input:focus) .value-preview {
  display: none;
}

.value-preview {
  position: absolute;
  width: calc(100% - 2px); /** to show the border of the input below */
  margin: 1px 0 0 1px;
  height: calc(48px - 2px);
  line-height: calc(48px - 2px); /* vertically center text */
  background-color: var(--bg-gray);
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 8px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.value-preview:hover {
  border-color: 1px solid var(--accent);
}
