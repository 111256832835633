.card {
  border-radius: 8px;
  height: 400px;
  width: calc(100%);
  background-color: rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); /*fixing safari round corners issueu*/
}

.card::after {
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0,
    rgba(255, 255, 255, 0.15) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(0, 0, 0, 0)
  );
  animation: shimmer 1.3s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
