.button-icon {
  font: var(--h6);
  box-sizing: border-box;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #16db70 !important;
  cursor: pointer;
  height: 32px;
  width: 32px;
  transition: all 0.2s;
}

.button-icon:hover {
  background-color: var(--bg-button-white-hover);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.button-icon.destructive:hover {
  background-color: var(--accent-red) !important;
}

.button-icon:active {
  padding-top: 2px;
  background-color: var(--bg-darker-dark);
}

.button-icon.destructive:active {
  background-color: var(--accent-red-clicked) !important;
}

.button-icon.light svg {
  color: var(--accent);
}

.button-icon.light:hover {
  background-color: var(--accent-hover);
  box-shadow: 0 1px 1px 0px rgba(36, 139, 83, 0.8);

  color: white !important;
}

.button-icon.light:hover svg {
  color: white;
}

.button-icon.light:active {
  background-color: var(--accent-clicked);
  box-shadow: none;

  color: white !important;
}

.button-icon.border {
  border: 1px solid var(--divider-white);
}

.button-icon.border svg {
  color: black;
  stroke: black;
}

.button-icon.border:hover svg {
  color: white;
  stroke: white;
}

.button-icon.border:active svg {
  color: white;
  stroke: white;
}

.button-icon.border.light {
  border: 1px solid var(--divider-solid);
}

.button-icon.border:hover {
  border: 1px solid transparent;
}

.button-icon.border :active {
  border: 1px solid transparent;
}

.button-icon.light.button-icon.destructive:hover {
  box-shadow: 0 1px 1px 0px var(--accent-red-clicked);
}
