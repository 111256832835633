.pricing {
  padding: 120px 32px;
  margin: auto;
  width: calc(100% - 64px);
}
.pricing h1 {
  text-align: center;
}

.pricing-options-container {
  width: calc(100% + 32px);
  overflow: auto;
  margin-left: -32px;
  padding: 0 0 0 32px;
}

.pricing-options {
  gap: 16px;
  z-index: 999;
  display: flex;
  margin: 84px auto 0;

  padding-bottom: 32px;
  width: 952px;
}

.pricing h1 {
  margin: 0 0 16px;
  font-weight: 500;
}

.pricing-plan {
  float: left;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  box-sizing: border-box;
  width: 296px;
  border-radius: 20px;
  /* height: 420px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s;
}

.resub .pricing-plan h1 {
  color: black;
}

.resub .pricing-plan {
  background-color: white;
}

.pricing-plan:hover {
  transform: scale(1.02);
}

.pricing-plan.left {
  transform-origin: bottom right;
}

.resub {
  --accent-yellow: #fb7700;
}

.pricing-plan.middle {
  /* height: 462px; */
  margin-top: -42px;
  transform-origin: bottom center;
}

.pricing-plan.right {
  transform-origin: bottom left;
}

.pricing-plan .padding {
  padding: 16px;
}

.pricing-plan .price {
  padding: 16px 16px 32px;
}

.pricing-plan h1 {
  font-size: 36px;
  font-weight: 500;
  margin: 4px 0;
  text-align: left;
}

.pricing-plan h2 {
  font-weight: 500;
  margin: 4px 0 16px;
}

.pricing-plan span {
  color: var(--secondary-text-white);
  font-size: 20px;
  font-weight: 400;
}

.pricing-plan h2 span {
  position: relative;
  top: -8px;
  right: -2px;
  font-size: 16px;
}

.pricing-plan p {
  margin: 0;
  color: var(--secondary-text-white);
}

.resub .pricing-plan span,
.resub .pricing-plan p {
  color: var(--secondary-text);
}

.pricing-plan .save {
  color: var(--accent-yellow);
  opacity: 0;
  transition: all 0.4s;
}

.pricing-plan .save.visible {
  opacity: 1;
}

.pricing-plan .sub-price {
  color: var(--secondary-text-white);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.pricing-plan .sub-price.visible {
  opacity: 1;
  max-height: 24px;
}

.pricing-plan .highlight {
  color: var(--accent-yellow);
  margin-bottom: 16px;
}

.extra {
  margin: 32px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extra div {
  display: flex;
  gap: 12px;
}

.extra h3 {
  font-size: 18px;
  font-family: Inter, sans-serif;
  text-align: center;
  margin: 0 auto;
  border-bottom: 1px solid var(--divider-white);
  width: auto;
  cursor: pointer;
}

.resub .extra h3 {
  border-color: var(--divider);
}

.extra h3:hover {
  color: var(--accent-hover);
  border-color: var(--accent-text);
}

.segment-input {
  max-width: 400px;
  margin: 40px auto 20px;
}

@media screen and (max-width: 480px) {
  .pricing-plan h1 {
    font-size: 30px;
  }

  .pricing-plan span,
  .pricing-plan p {
    font-size: 14px;
  }

  .pricing {
    padding-top: 100px;
  }

  .pricing-options {
    margin-top: 24px;
  }
  /* .pricing-plan {
   height: 374px; 
  } */
  .pricing-plan.middle {
    height: calc(374px + 42px);
    margin: 0;
  }

  .segment-input {
    max-width: 400px;
    margin: 24px 0 0;
  }
}
