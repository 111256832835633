.everything {
  transition: height 0.2s;
  margin-bottom: 8px;
  transition: scale 0.2s;
  margin-left: -24px;
  padding-left: 24px;
}

.overlay {
  width: 100%;
  height: 48px;
  /* margin-top: -48px; */
  position: relative;
  cursor: grabbing;
  display: none;
}

.drag-overlay {
  position: relative;

  cursor: ns-resize !important;
  z-index: 100;
  scale: 1.05;
}

.drag-icon {
  width: 26px;
  margin-left: -24px;
  cursor: ns-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}
.everything:hover .drag-icon,
.drag-overlay .drag-icon,
.sorting .drag-icon {
  opacity: 1;
}

.drag-icon svg {
  transform: scaleX(0.64);
  stroke: #ccc;
}

.drag-icon:hover svg,
.drag-overlay .drag-icon svg {
  stroke: var(--accent);
}

.drag-overlay .overlay {
  display: block;
  cursor: ns-resize !important;
}

.drag-overlay .inputs-and-icon-container {
  box-shadow: var(--shadow-elevated);
}

.labels {
  height: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.drag-overlay .labels {
  opacity: 0;
}

.hide {
  opacity: 0;
}

.inputs-container {
  width: 100%;
  border-radius: 4px;
  display: flex;
  float: left;
  background-color: white;
}

.inputs-and-icon-container {
  display: flex;
  border-radius: 4px;
  transition: all 0.2s;
  position: relative;
}

.everything:has(.drag-icon:hover) .inputs-and-icon-container {
  box-shadow: var(--shadow-base);
  z-index: 10;
}
.everything:hover .inputs-and-icon-container {
  height: 48px;
  z-index: 10;
}
.inputs-and-icon-container:hover input,
.everything:has(.drag-icon:hover) input,
.inputs-and-icon-container:hover .icon-container {
  border-color: var(--accent-hover);
}

.move-input,
.effort-input,
.reps-input {
  float: left;
  background-color: none;
  box-sizing: border-box;
}

.reps-input {
  border-right: none;
  border-left: none;
  border-radius: 0;
  width: 56px;
  padding-right: 0;
  text-align: center;
}

.effort-input {
  width: 25%;
  padding: 0 8px 0 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: right;
  border-right: none;
  border-radius: 0;
  padding-right: 0;
}

.icon-container {
  color: var(--accent-text);
  cursor: pointer;
  padding: 0 8px 0;
  height: 48px;
  display: flex;
  float: right;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid var(--divider-solid);
  border-left: none;
  box-sizing: border-box;
  background-color: var(--bg-gray);
}

.icon-container:hover {
  color: var(--accent-hover);
  /*background-color:var(--bg-gray);*/
}

.everything.first,
.everything.middle {
  margin-bottom: -1px;
}

.everything.first .icon-container,
.everything.first .moves-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.everything.middle .icon-container,
.everything.middle .reps-input {
  border-radius: 0;
}

.everything.last .icon-container,
.everything.last .reps-input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
