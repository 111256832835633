.additional-note {
  margin: 12px 0 0px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
}

.additional-note .buttons {
  display: flex;
  justify-content: space-between;
}

.additional-note .buttons .section {
  display: flex;
  gap: 8px;
}

.additional-note label {
  margin: 0;
}
