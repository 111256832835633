.bg {
  width: 100%;
  height: 100%;
  background: url(../../../../public/img/cal-bg.jpg) no-repeat;
  background-size: cover;
}

.bg.solid {
  background: #888;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.8);
}

.block {
  width: 320px;
  background-color: white;
  padding: 24px 32px 32px;
  border-radius: 8px;
}

.block h3 {
  margin: 24px 0 12px;
}

.block h1 span {
  font: var(--h3);
}

.store-buttons {
  display: flex;
  gap: 8px;
}

.app-store-button {
  cursor: pointer;
  margin: 12px 0 0 -3px;
  border: 3px solid transparent;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
}

.app-store-button img {
  height: 44px;
}

.app-store-button:hover {
  border-color: var(--accent);
}

.links {
  position: absolute;
  color: var(--secondary-text-white);
  font: var(--small-text);
  right: 24px;
  top: 24px;
  display: flex;
  gap: 24px;
}

.links span {
  cursor: pointer;
}

.links span:hover {
  color: var(--accent-hover);
}

.links span:active {
  color: var(--accent-clicked);
}
