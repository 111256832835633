.wrapper {
  --transition-time: 0.8s;
  display: flex;
}

.app-store-promo {
  color: white;
  width: 0;
  height: 100vh;
  background-color: black;
  background: url(../../../public/img/mobile-splash.jpg) no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: all var(--transition-time);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.hello .app-store-promo {
  width: 50%;
  opacity: 1;
}

.store-buttons {
  display: flex;
  gap: 8px;
}

.app-store-promo section,
.start-flow-intro section {
  height: 480px;
}
.app-store-promo section p,
.start-flow-intro p {
  margin-top: 16px;
  margin-bottom: 24px;
}

.hello .app-store-promo section {
  opacity: 1;
}

.app-store-promo section /*.main-hello section */ {
  width: 320px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: all var(--transition-time);
  flex-shrink: 0;
}

.app-store-promo h2 {
  margin-top: 81px; /* compensation for logo in the right section */
  z-index: 1;
}

.app-store-promo p {
  border-left: 3px solid var(--accent);
  padding-left: 24px;
  margin-left: -27px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 50px 40px rgba(0, 0, 0, 0.5);
}

.app-store-button {
  cursor: pointer;
  margin: -3px 0 0 -3px;
  border: 3px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
}

.app-store-button:hover {
  border-color: var(--accent);
}

.app-store-button img {
  height: 44px;
}

.start-flow-intro {
  opacity: 0;
  width: 0vw;
  flex-shrink: 0;
  height: 100vh;
  transition: all var(--transition-time);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
}

.hello .start-flow-intro {
  width: 50vw;
  opacity: 1;
}

.start-flow-intro section {
  flex-shrink: 0;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background: radial-gradient(
    50vh 50vh at 50% 50%,
    rgba(22, 219, 112, 0.25) 0%,
    rgba(22, 219, 112, 0) 100%
  );
  background-color: #000;
  transition: all var(--transition-time);
}

.hello .container {
  width: 50vw;
}

.onboarding-flow {
  position: absolute;
  flex-shrink: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  color: white;
  top: 0;
  right: 0;
  transition: all var(--transition-time);
}

.hello .onboarding-flow {
  opacity: 0;
  right: -100vw;
  pointer-events: none;
}

.header {
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 48px;
}

.links {
  font: var(--small-text);
  margin-top: 48px;
  width: 128px; /*so that it would be aligned with the logo*/
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.link:hover {
  color: var(--accent-hover);
}

.progress {
  margin-top: 62px;
}

.modules {
  width: 300vw;
  height: 100%;
  margin-left: -100vw;
  transition: 0.6s margin-left;
  display: flex;
}
.module {
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}

.module-content {
  width: 100%;
}

.module > div:first-child {
  margin: auto;
}

.logo {
  margin: 40px 0 0 0 !important;
  width: 96px !important;
}

.full {
  display: none;
}

.hello.full {
  height: 100vh;
}

.full .logo {
  margin: 40px 0 0 43px !important;
  width: 120px !important;
}

.full .app-store-promo {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
}

.full h2,
.full h3 {
  margin-top: 0;
  margin-left: 43px;
  position: relative;
  z-index: 2;
}

.full h3 {
  margin-bottom: -8px;
}

.full .app-store-promo section p {
  max-width: 100%;
  box-sizing: border-box;
  margin-left: 16px;
}

.full .app-store-promo section {
  max-width: 100%;
  box-sizing: border-box;
  height: auto;
}

.full .store-buttons {
  margin-left: 43px;
}

@media only screen and (max-width: 800px) {
  .header {
    width: 100%;
    height: 80px;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
  }
  .logo {
    margin: 12px 0 0 32px !important;
    width: 80px !important;
  }
}
@media only screen and (max-width: 720px) {
  .wrapper {
    display: none;
  }
  body {
    min-width: auto;
  }

  .full {
    display: block;
  }
}
