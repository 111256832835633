.everything {
  margin-bottom: 8px;
  --highlight-animation-duration: 0.2s;
  --animation-loop-duration: 2s;
  --gap-size: 1px;
}

.overlay {
  width: 100%;
  height: 48px;
  margin-top: -48px;
  position: relative;
  cursor: grabbing;
  display: none;
}

.drag-overlay {
  position: relative;
  z-index: 100;
  scale: 1.05;
}

.drag-overlay .overlay {
  display: block;
  cursor: ns-resize;
}

.labels {
  height: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.labels span {
  /* Edit link */
  font: var(--h6);
  cursor: pointer;
  color: var(--accent-text);
}

.labels span:hover {
  color: var(--accent-hover);
}

.drag-overlay .labels {
  opacity: 0;
}

.drag-overlay .gradient-background {
  box-shadow: var(--shadow-elevated);
}

.drag-icon {
  width: 24px;
  margin-left: -24px;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
}

.everything:hover .drag-icon,
.drag-overlay .drag-icon,
.sorting .drag-icon {
  opacity: 1;
}

.drag-icon svg {
  transform: scaleX(0.64);
  stroke: #ccc;
  margin-top: 14px;
}

.drag-icon:hover svg,
.drag-overlay .drag-icon svg {
  stroke: var(--accent);
}

.hide {
  opacity: 0;
}

.gradient-background {
  position: relative; /* For absolute positioning of the pseudo-element */
  display: flex;
  border-radius: 4px;
  /* width: 100%; */
  /* height: 50px;  Ensure the height accommodates the gradient size */
  --gradient-size: 500px; /* Size of the gradient circle */
  overflow: hidden;
  transition: all var(--highlight-animation-duration);
  background-color: var(--divider-solid);
  padding: var(--gap-size);
}

.animated .gradient-background {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Pseudo-element for the gradient circle */
.gradient-background::after {
  content: '';
  position: absolute;
  width: var(--gradient-size);
  height: var(--gradient-size);
  background: radial-gradient(
    circle at center,
    var(--accent) 0%,
    var(--accent-highlight) 25%,
    transparent 50%
  );
  /* Position the circle so its center is on the edge */
  top: calc(-1 * var(--gradient-size) / 2);
  left: calc(-1 * var(--gradient-size) / 2);
  transition: all var(--highlight-animation-duration);
  opacity: 0;
}

.animated .gradient-background::after {
  opacity: 1;
  animation: move-gradient var(--animation-loop-duration) infinite linear;
}

/* Keyframes for moving the gradient circle along the edges */
@keyframes move-gradient {
  0% {
    top: calc(-1 * var(--gradient-size) / 2); /* Top edge */
    left: calc(-1 * var(--gradient-size) / 2); /* Left edge */
  }
  44.47% {
    top: calc(-1 * var(--gradient-size) / 2); /* Top edge */
    left: calc(100% - var(--gradient-size) / 2); /* Right edge */
  }
  50% {
    top: calc(100% - var(--gradient-size) / 2); /* Bottom edge */
    left: calc(100% - var(--gradient-size) / 2); /* Right edge */
  }
  94.47% {
    top: calc(100% - var(--gradient-size) / 2); /* Bottom edge */
    left: calc(-1 * var(--gradient-size) / 2); /* Left edge */
  }
  100% {
    top: calc(-1 * var(--gradient-size) / 2); /* Top edge */
    left: calc(-1 * var(--gradient-size) / 2); /* Left edge */
  }
}
.gradient-background:hover .icon-container {
  border-color: var(--accent-hover);
  z-index: 9999;
}
/* 
.text-container {
  width: 100%;
  border-radius: 4px;
  display: flex;
  background-color: white;
  margin-right: -34px;
} */

.text-container {
  font-style: normal;
  /* height: 100%; */
  padding: 10px 8px;
  overflow-y: hidden;
  resize: none !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all var(--highlight-animation-duration);
  flex: 1 0;
  width: 100%;
  min-height: 48px;
}

.text-container.placeholder-note {
  color: var(--secondary-text);
}

.animated .text-container {
  padding: 9px 7px;
  min-height: 46px;
}

.icon-container {
  color: var(--accent-text);
  cursor: pointer;
  width: 32px;
  height: 46px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 32px;
  left: 0;
  transition: all var(--highlight-animation-duration);
}

.animated .icon-container {
  height: 44px;
  left: 1px;
}

.icon-container:hover {
  color: var(--accent-hover);
  /*background-color:var(--bg-gray);*/
}

.all-contents {
  display: flex;
  border-radius: calc(4px - var(--gap-size));
  /* top: var(--gap-size);
  left: var(--gap-size); */
  /* height: calc(100% - var(--gap-size) * 2); */
  background-color: white;
  /* width: calc(100% - var(--gap-size) * 2); */
  position: relative;
  z-index: 2;
  transition: all var(--highlight-animation-duration);
  /* height: 100%;*/
  width: 100%;
}

.animated.everything {
  --gap-size: 2px;
}
