.workout-card {
  box-sizing: border-box;
  color: var(--primary-text);

  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  box-shadow: var(--shadow-base);
  z-index: 1;
  position: relative;
  left: 0;
  transition: box-shadow 0.2s;

  --base-size: 16px;

  --main-text-size: 18px;
  --secondary-text-size: 16px;
  --margin-xl: 32px;
  --margin-l: 24px;
  --margin-m: var(--base-size);
  --margin-s: 12px;
  --margin-xs: 8px;
  --margin-xxs: 4px;
}

.workout-card.animate-on-appear {
  animation: appear 0.3s ease-out;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tv.workout-card {
  background-color: black;
  outline: none;
  border: none;
  box-shadow: none;
  --text: white;
  --primary-text: white;

  --base-size: calc(100vw * 0.036);
  --main-text-size: calc(var(--base-size) * 1.125);
  --secondary-text-size: calc(var(--base-size) * 0.85);

  --margin-xl: calc(var(--margin-m) * 2);
  --margin-l: calc(var(--margin-m) * 1.5);
  --margin-m: var(--base-size);
  --margin-s: calc(var(--margin-m) / 4 * 3);
  --margin-xs: calc(var(--margin-m) / 2);
  --margin-xxs: calc(var(--margin-m) / 4);

  margin-bottom: 0;
}

.workout-card.scoreview {
  position: sticky;
  top: 0;
  transform: translateX(calc(24px - 100vw)) !important;
}

.workout-card .content {
  padding: 16px 24px 24px 16px;
  display: flex;
  flex-direction: column;
}

.tv.workout-card .content {
  padding: 0;
}

.workout-card h6 {
  margin: 0;
  padding: 0;
}

.drag-icon {
  float: right;
  margin: -2px 0 0 12px;
  cursor: ns-resize;
}

.drag-icon svg {
  stroke: #ccc;
}

.drag-icon:hover svg {
  stroke: var(--accent);
}

.workout-card:has(.drag-icon:hover) {
  box-shadow: var(--shadow-elevated);
}

.title-container {
  line-height: 24px;
  margin: var(--margin-m) var(--margin-l) var(--margin-s);
}
.title {
  text-transform: capitalize;
  font: var(--h3);
}

.header {
  height: 48px;
}

.workout-type,
.workout-type-tiny,
.no-tracking {
  margin-bottom: 8px;
  color: var(--secondary-text);
  float: left;
  padding: 12px 0 12px 24px;
}

.workout-type-tiny {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  font-weight: 600;
}

.gym-link {
  --ion-color-primary: var(--accent-text);
  text-align: right;
}

.no-tracking {
  float: right;
  padding: 12px 0 12px 0;
}
.workout-type h4 {
  margin: 0;
}

.moves {
  width: 100%;
  /* height: 100%; */
  margin-top: var(--margin-xs);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-s);
}

.tv .moves {
  box-sizing: border-box;
  padding-bottom: var(--margin-xs);
  display: flex;
  margin-bottom: 0;
}

.moves .item {
  float: left;
  width: 85%;
  margin: 0;
  border-collapse: collapse;
  margin: var(--margin-xs) 0;
}

.tv .moves .item.exercise {
  width: 100%;
  margin: 0 !important;
  padding: var(--margin-xxs) 0;
}

.tv .moves .item.exercise:hover {
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: var(--margin-xxs);
}
.moves .item:first-child {
  margin-top: 0 !important;
}
.moves .item:last-child {
  margin-bottom: 0;
}

.icon {
  float: left;
  margin-right: var(--margin-s);
}

.icon svg {
  width: var(--margin-s);
  height: var(--margin-s);
}

.tv .icon svg {
  width: calc(var(--margin-s) * 0.9);
  height: calc(var(--margin-s) * 0.9);
  position: relative;
  top: calc(-1 * var(--margin-xxs) * 0.2);
}

.text {
  float: left;
  width: calc(100% - var(--margin-xl));
}

.move-name {
  font-weight: 500;
  margin-right: 2px;
}

.reps {
  color: var(--secondary-text);
  font-size: var(--secondary-text-size);
  white-space: nowrap;
}

.tv .reps {
  font-size: var(--main-text-size);
  font-weight: 400;
}

.tv.conditioning .reps,
.tv.endurance .reps,
.tv.conditioning .repetition,
.tv.endurance .repetition {
  color: #56f29f;
}

.tv.strength .reps,
.tv.strength .repetition {
  color: var(--accent-red);
}

.tv.skill .reps,
.tv.skill .repetition {
  color: var(--accent-yellow);
}

.tv.heroes .reps,
.tv.cf-open .reps,
.tv.heroes .repetition,
.tv.cf-open .repetition {
  color: var(--accent-blue);
}

.tv.girls .reps,
.tv.special .reps,
.tv.girls .repetition,
.tv.special .repetition {
  color: var(--accent-orange);
}

.tv .times-symbol {
  color: var(--secondary-text-white);
  font-size: var(--secondary-text-size);
  position: relative;
  top: calc(0px - var(--margin-xs) / 4);
}

.reps strong {
  font-weight: 500;
  font-size: var(--main-text-size);
  color: var(--accent-text);
}

.effort {
  color: var(--text);
  margin-left: var(--margin-xxs);
}

.exercise {
  font-size: var(--main-text-size);
  line-height: 150%; /*24px*/
  margin: var(--margin-xxs) 0 !important;
}

.tiny .exercise {
  font-size: var(--secondary-text-size);
}

.exercise.tappable {
  font-size: var(--main-text-size);
  border-bottom: 1px solid var(--divider-solid);
  border-top: 1px solid var(--divider-solid);
  padding: var(--margin-xs) 0;
  margin: 0 0 -1px !important;
}

.note {
  font-size: var(--secondary-text-size);
  line-height: 150%;
  padding: 0 var(--margin-l);
  color: var(--secondary-text);
  white-space: pre-line;
}
.note.additional {
  margin-top: var(--margin-xs);
}

.predefined .moves .item.note {
  color: black;
}

.tv .moves .item.note,
.tv .note.additional {
  color: var(--secondary-text-white);
}

.workout-card.notes-only .moves .item.note {
  color: var(--primary-text);
}

.tv .workout-card.notes-only .moves .item.note {
  color: white;
}

.rest {
  font-size: var(--secondary-text-size);
  color: var(--text);
  font-style: italic;

  padding: 0 var(--margin-l);
}

.repetition,
.time-cap {
  margin: var(--margin-xs) var(--margin-l) var(--margin-xs) var(--margin-l);
  font-weight: 400;
  line-height: 150%;
  color: var(--text);
  white-space: pre-line;
  font-size: var(--secondary-text-size);
}

.time-cap {
  color: var(--secondary-text);
}

.tv .time-cap {
  color: white;
  margin-top: 0;
}

.repetition:first-child {
  margin-top: 0;
  border-top: 0;
  padding-top: 0;
}

.drag-overlay {
  position: relative;
  z-index: 100;
  box-shadow: var(--shadow-elevated);
  cursor: grabbing;
}
.hide {
  visibility: hidden;
}

ion-modal {
  --background: none;
  --backdrop-opacity: 0.8;
}

.tracking-type {
  padding: var(--margin-m) var(--margin-l) 0 var(--margin-l);
  border-top: 1px solid var(--divider);
  margin-top: var(--margin-l);
}

.tracking-type h6 {
  color: var(--secondary-text);
  margin-bottom: 4px;
}

.editor-buttons {
  margin: 24px 0 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.editor-buttons .group {
  display: flex;
  justify-content: space-between;
}

.editor-buttons .section {
  display: flex;
  gap: 8px;
}

.publish-buttons {
  transition: all 0.2s;
  width: 100px;
}

.publish-buttons.published {
  margin: 0;
  width: 0px;

  overflow: hidden;
  opacity: 0;
}

.publish-buttons .secondary-label,
.publish-buttons .da-button {
  width: 100px;
}

.publish-buttons .da-button {
  display: none;
  width: 100px;
}

.publish-buttons:hover .da-button {
  display: flex;
}

.publish-buttons:hover .secondary-label {
  display: none;
}
