.segmented-input {
  background-color: var(--bg-gray);
  border-radius: 4px;
  position: relative;
  padding: 0px;
  border: 1px solid var(--divider-solid);
}

.segmented-input.dark {
  background-color: rgba(0, 0, 0, 0.3);

  border: 1px solid black;
}

.segmented-input.dark .plate {
  background-color: transparent;
  border: 2px solid var(--accent);

  box-sizing: border-box;
}
.segmented-input .segments div.active {
  font-weight: 500;
}

.segments {
  width: 100%;
  display: flex;
  height: 100%;
}

.segmented-input .segments div:not([role='tooltip']) {
  border-radius: 3px;
  flex: 1;
  padding: 6px;
  text-align: center;
  font: var(--small-text);
  z-index: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.segmented-input .segments svg {
  position: relative;
  margin: 0 6px 0 -8px;
}

.segmented-input .segments .no-label svg {
  margin: 0px;
}

.segmented-input .segments div:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.segmented-input.dark .segments div:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.segmented-input .segments div.active:hover {
  background-color: transparent;
  cursor: default;
}

.plate {
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  position: absolute;
  left: 0px;
  top: 0px;

  height: calc(100% - 0px);
  width: 100%;
  z-index: 0;
}

.animated .plate {
  transition: left 0.2s;
}

.segmented-input .segments div.large-text {
  font: var(--p);
}
