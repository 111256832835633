.wrapper {
  display: flex;
}

.wrapper.disabled {
  opacity: 0.5;
}

.wrapper:not(.disabled) .container {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
}

.disabled .container {
  cursor: not-allowed;
}
.container {
  display: flex;
}

.disabled .container {
  cursor: not-allowed;
}
.container {
  display: flex;
  align-items: center;
}

.disabled .container {
  cursor: not-allowed;
}

.toggle {
  width: 48px;
  height: 24px;
  background: #aaa;
  border-radius: 24px;
  cursor: pointer;
  float: left;
  margin-right: 8px;
}

.tiny .toggle {
  width: 24px;
  height: 12px;
  margin-right: 6px;
}

.tiny .toggle {
  width: 24px;
  height: 12px;
  margin-right: 6px;
}

.disabled .toggle {
  cursor: not-allowed;
}

.wrapper:not(.disabled) .container:hover .toggle {
  background-color: #999;
}

.wrapper:not(.disabled) .container:hover .toggle.active {
  background-color: var(--accent-hover);
}

.pipka {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0px 0 0 0px;
  position: relative;
  top: 4px;
  left: 4px;
  transition: all 0.2s;
}

.tiny .pipka {
  width: 8px;
  height: 8px;
  top: 2px;
  left: 2px;
}

.toggle.active {
  background: var(--accent);
}

.active .pipka {
  left: 28px;
}

.tiny .active .pipka {
  left: 14px;
}
