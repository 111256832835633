.header {
  position: relative;
  padding-bottom: 24px;
  height: auto !important;
  box-sizing: content-box;
}

.subheader {
  min-height: 24px;
}

#workout-builder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 24px;
  flex: 1;
  overflow-y: scroll;
  box-sizing: border-box;
}

#workout-builder h3 {
  margin: 32px 0 8px;
}

.close-icon div {
  color: var(--accent);
  position: absolute;
  right: 24px;
  top: 28px;
}
.top-items {
  display: flex;
  flex-direction: column;
}

#workout-builder span {
  font-weight: 400;
}

.extype {
  width: 128px;
  border: 1px solid var(--divider);
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  height: auto;
}

.extype:focus {
  padding-bottom: 2px;
  padding-top: 4px;
}

.extype .icon {
  font-size: 4px;
}

.extype.strength .icon {
  background-color: var(--accent-red);
}
.extype.conditioning .icon {
  background-color: var(--accent-green);
}
.extype.mobility .icon {
  background-color: var(--accent-yellow);
}

#workout-builder input,
#workout-builder textarea,
#workout-builder select,
#workout-builder select option {
  background-color: var(--bg-gray);
}

#workout-builder .half {
  width: calc(50% - 8px) !important;
}
#workout-builder .left {
  float: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

#workout-builder .left,
#workout-builder .right {
  float: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

#workout-builder .right {
  float: right;
}

#workout-builder textarea {
  resize: vertical;
}

.subheader-and-link {
  margin-top: 9px;
  float: left;
  width: 80%;
}

.subheader-and-link h3 {
  width: auto !important;
  float: left;
}

.subheader-and-link .title-link {
  color: var(--accent-text);
  cursor: pointer;
  font: var(--h5);
}

.subheader-and-link .title-link:hover {
  color: var(--accent-hover);
}

.bottom-items {
  padding-top: 48px;
}

.scroll-shadow {
  background: radial-gradient(50% 16px at top, rgba(0, 0, 0, 0.1), transparent);
  width: 100%;
  height: 16px;
  position: absolute;
  z-index: 999;
  margin-left: 50%;
  left: -50%;
  bottom: -16px;
  pointer-events: none;
  transition: opacity 0.2s;
  opacity: 0;
}

.scroll-shadow.visible {
  opacity: 1;
}

.no-scaling-placeholder {
  background-color: var(--bg-gray);
  padding: 8px;
  margin: 0 24px;
  border-radius: 16px;
}
.no-scaling-placeholder div {
  border-radius: 4px;
  border: 1px dashed var(--divider);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 14px;
  gap: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.no-scaling-placeholder:hover {
  background-color: var(--accent-highlight);
}
.no-scaling-placeholder:hover div {
  background-color: var(--accent-highlight);
  border-color: var(--accent);
}

.no-scaling-placeholder:active div {
  padding-top: 4px;
}

.bottom-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
