.bg {
  background-color: var(--bg-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  margin: 0 auto;
}

.container h1 {
  font-size: 40px;
}

.logo {
  width: 320px !important;
  margin: 40px 0 32px !important;
}

.logo img {
  width: 100%;
}

.text.ins {
  margin: 24px 0 8px;
  font-weight: 600;
}

.qr {
  width: 48%;
  display: block;
  margin: 0 auto;
}

.qr svg {
  width: 100%;
  height: max-content;
}

.code {
  width: 80%;
  border: 2px solid var(--divider);
  text-transform: uppercase;
  font-size: 32px;
  font-family: 'Work Sans';
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  letter-spacing: 10px;
  border-radius: 8px;
  padding: 24px 0;
  margin: 0 auto 24px;
}

.link {
  font-size: 12px;
  margin-top: -4px;
}
