.backdrop {
  position: fixed;
  z-index: 1300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.15s;
  opacity: 0;
  pointer-events: none;
}

.backdrop.visible {
  opacity: 1;
  pointer-events: all;
}
.backdrop.visible.blurred {
  backdrop-filter: blur(10px);
}

.backdrop .modal-content {
  transition: all 0.15s;
  top: 20px;
  transform: scale(0.95);
}

.backdrop.visible .modal-content {
  top: 0;
  left: 0;
  transform: scale(1);
}

.modal-content {
  z-index: 100500;
  background-color: white;
  box-shadow: var(--shadow-elevated);
  padding: 32px 32px 24px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

.modal-content.small {
  width: 480px;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
}

.buttons {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.buttons button {
  flex-shrink: unset;
}

.header {
  margin-bottom: 16px;
}
