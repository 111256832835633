#mainnav-container {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--bg-dark);
  z-index: 99;
  display: flex;
  padding: 0 88px 0 32px; /*total padding is 104 px but A element padding is 16px*/
}

nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

nav div {
  display: block;
  font: var(--h5);
}

nav div.left,
nav div.right {
  display: flex;
}

nav a {
  color: var(--secondary-text-white);
  padding: 0 16px 0;
  display: block;
  float: left;
  transition: all 0.1s;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

nav a span {
  padding-top: 32px;
  display: block;
}

nav a.active span {
  padding-top: 29px;
  border-top: 3px solid var(--accent);
}

nav a:hover:not(.active) {
  color: white;
  /* background-color: rgba(255, 255, 255, 0.05); */
  background-color: var(--bg-button-white-hover);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
nav a.active:hover {
  cursor: default;
}

nav a.active {
  color: var(--accent);
  color: white;
  box-sizing: border-box;
}
