.button-toolbar {
  font: var(--h6);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 12px;
  color: #16db70 !important;
  color: white !important;
  border: 1px solid var(--divider-white);
  cursor: pointer;
  height: 32px;
  transition: all 0.1s;
  font-weight: 500;
}

.disabled.button-toolbar {
  cursor: default;
  opacity: 0.5;
}
.button-toolbar.large {
  height: 36px;
  font: var(--h5);
  font-weight: 500;
}

.button-toolbar:hover:not(.disabled) {
  border: 1px solid transparent;
  background-color: var(--bg-button-white-hover);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.button-toolbar:active:not(.disabled) {
  border: 1px solid transparent;
  padding-top: 2px;
  background-color: var(--bg-darker-dark);
}

.button-toolbar svg {
  margin: 0 8px 0 -2px;
}

.no-text.button-toolbar svg {
  margin: 0;
}

.button-toolbar.light {
  border-color: var(--divider-solid);
  color: black !important;
}

.button-toolbar.light svg {
  color: var(--accent);
}

.disabled.light svg {
  color: var(--divider);
}

.button-toolbar.light:hover:not(.disabled) {
  background-color: var(--accent-hover);
  box-shadow: 0 1px 1px 0px rgba(36, 139, 83, 0.8);
  border-color: transparent;
  color: white !important;
}

.button-toolbar.light:hover:not(.disabled) svg {
  color: white;
  stroke: white !important;
}

.button-toolbar.light:hover:not(.disabled) svg {
  color: white;
}

.button-toolbar.light:active:not(.disabled) {
  background-color: var(--accent-clicked);
  box-shadow: none;
  border-color: transparent;
  color: white !important;
}
